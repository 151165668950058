import React from 'react';
import Cookies from 'cookies';
import { DEFAULT_AUTH_COOKIE_NAME } from '@cvent/nextjs/auth/constants';
import getConfig from 'next/config';
import { useTheme } from '@cvent/carina/components/ThemeProvider';
import authClient from '@shared-resource/auth-client';
import { getItem } from '@cvent/nextjs/utils/storage';
import { MetadataFields } from '@components/types';

const { publicRuntimeConfig } = getConfig();

const loginRedirectRemote = {
  redirect: {
    destination: publicRuntimeConfig.LOGIN_URL,
    permanent: false
  }
};

const loginRedirectLocal = {
  redirect: {
    destination: '/login',
    permanent: false
  }
};

export async function getServerSideProps({ req, res }) {
  const cookies = new Cookies(req, res);
  const authToken = cookies.get(DEFAULT_AUTH_COOKIE_NAME);
  const redirectTarget = publicRuntimeConfig.DEV_LOGIN === 'true' ? loginRedirectLocal : loginRedirectRemote;

  if (authToken === undefined) {
    return redirectTarget;
  }

  const cventAuthServiceResponse = await authClient.accessToken.verify(authToken);
  if (cventAuthServiceResponse === undefined || cventAuthServiceResponse === null) return redirectTarget;

  return { props: {} };
}

/**
 * Represents the main landing page. Contains nested structure.
 */
export default function IndexPage(): JSX.Element {
  const { backgroundColor } = useTheme();
  const name = getItem(MetadataFields.USER_NAME);

  return (
    <div
      style={{
        backgroundColor: backgroundColor.focus
      }}
    >
      <div
        style={{
          display: 'block',
          width: '100%'
        }}
      >
        <h1>passkey-room-categories-ui</h1>
        <h2>User name: {name}</h2>
      </div>
    </div>
  );
}
