import { gql } from '@apollo/client';

export const getJsonWebToken = gql`
  query getJsonWebToken($payload: JSON!) {
    jsonWebToken(payload: $payload) {
      token
    }
  }
`;

export const getDevAuth = gql`
  query getDevAuth($username: String!, $password: String!) {
    devAuth(username: $username, password: $password) {
      authorized
      accessToken
      csrfToken
      metadata {
        userId
        participantId
        username
        firstName
        lastName
        emailAddress
        objectTypeId
        userTypeId
        reportingLevel
        hasSisterHotels
        userStatus
        invalidLoginCount
        disabled
        customerFlags
        creditCardInfoViewable
        testUser
        apiUser
        adminUser
        participantType
        accountMappingId
        UserStub
        isCSO
      }
    }
  }
`;

export const verifyJsonWebToken = gql`
  query verifyJsonWebToken($token: String!) {
    verifyJsonWebToken(token: $token)
  }
`;
