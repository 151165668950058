import React, { useCallback, useEffect } from 'react';
import { useRouter } from 'next/router';
import Cookies from 'cookies';
import { Logger } from '@cvent/nucleus-logging';
import { initializeStorage } from '@components/userSession/utils';
import { PasskeyDevLogin } from '@components/PasskeyDevLogin';
import { DEFAULT_AUTH_COOKIE_NAME, AUTH_COOKIE_NAME } from '@cvent/nextjs/auth/constants';
import IndexPage from '@pages/index';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import cookie from 'js-cookie';
import { MetaData } from '@components/types';
import { setItem } from '@cvent/nextjs/utils/storage';
import getConfig from 'next/config';
import { useLazyQuery } from '@apollo/client';
import { getDevAuth } from '@operations/passkeyAuthentication';
import authClient from '@shared-resource/auth-client';

const { publicRuntimeConfig } = getConfig();
const LOG = new Logger('LoginHandler');

const redirect404 = {
  redirect: {
    destination: '/404',
    permanent: false
  }
};

export const RESDESK_COOKIE = `RD4_ID_${publicRuntimeConfig.COOKIE_HOST}`;

export async function getServerSideProps({ req, res }) {
  // When running in dev mode, cvent-auth and resdesk cookies won't be supplied.
  // ONLY supply API Key as public prop when running locally (dev laptop).
  if (publicRuntimeConfig.DEV_LOGIN === 'true') {
    return {
      props: {}
    };
  }

  const cookies = new Cookies(req, res);
  const authToken = cookies.get(AUTH_COOKIE_NAME);
  const rdToken = cookies.get(RESDESK_COOKIE);

  if (!authToken) return redirect404;

  const cventAuthServiceResponse = await authClient.accessToken.verify(authToken);
  LOG.debug('###Auth Service response : ' + JSON.stringify(cventAuthServiceResponse));

  if (!cventAuthServiceResponse) return redirect404;

  return {
    props: {
      csrfToken: cventAuthServiceResponse?.csrfToken || '',
      authToken: authToken || '',
      rdToken: rdToken?.split('"')?.join('') || '',
      metadata: cventAuthServiceResponse?.authorization?.metadata || ''
    }
  };
}

export default function LoginPage(props?): JSX.Element {
  const router = useRouter();
  const { query } = router;

  const [loginQuery] = useLazyQuery(getDevAuth);

  const login = useCallback(
    async (event, formValues) => {
      // Authenticate user and generate access token for local development workflow

      const response = await loginQuery({
        variables: formValues.values
      });
      const responseValues = response.data.devAuth;
      LOG.debug('DEV Login Response: ' + JSON.stringify(responseValues));

      if (response.data.devAuth.authorized) {
        cookie.set(DEFAULT_AUTH_COOKIE_NAME, responseValues?.accessToken);

        // DEV login, change the token with valid one during development.
        cookie.set(
          RESDESK_COOKIE,
          'qJDtMCT81ag5ZerbR7IUkdY3TBZlZbthebeuEtfsK6fkk2sVNCs5yHvac9pGof13tZV7RjCfj6kenmBr5+OO/Q=='
        );

        const metadata: MetaData = responseValues?.metadata;

        setItem('csrfToken', responseValues?.csrfToken);
        setItem('accessToken', responseValues?.accessToken);
        initializeStorage(metadata);
        router.push((query.returnUrl as string) || '/');
      }
    },
    [query.returnUrl, router, loginQuery]
  );

  useEffect(() => {
    if (publicRuntimeConfig.DEV_LOGIN !== 'true') {
      localStorage.setItem('csrfToken', props?.csrfToken);
      initializeStorage(props?.metadata);
      router.push('/');
    }
  }, [props, router]);

  if (publicRuntimeConfig.DEV_LOGIN !== 'true') {
    return <IndexPage />;
  }
  return <PasskeyDevLogin onSubmit={login} />;
}
